import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";

import Authenticator from "../../core/Authenticator";

import schoolLogo from "../../images/school-logo.svg";

async function sendPasswordReset(email: string) {
    const authenticator = new Authenticator();
    const success = authenticator.initiatePasswordReset(email);

    if (success) {
        $("#reset-success").show();
    } else {
        $("#reset-fail").show();
    }
}

const PasswordResetPage: React.FC = () => {
    const [email, setEmail] = useState();
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        sendPasswordReset(email);
    };

    return (
        <div className="container">
            <div className="row mt-4 justify-content-center align-items-center">
                <div className="col-lg-4">
                    <img src={schoolLogo} alt="St. Martin de Porres Logo" className="col-12 logo" />
                    <div className="card mt-4">
                        <div className="card-body">
                            <div id="reset-success" className="alert alert-success collapse">
                                If your email is registered with us a password reset email has been sent.
                            </div>
                            <div id="reset-fail" className="alert alert-danger collapse">
                                We were unable to complete your request. Please try again.
                            </div>
                            <form onSubmit={formSubmit}>
                                <input type="email" className="form-control mt-2" placeholder="Email" onChange={handleEmailChange} />
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn-primary col-12 psa-button">Send Reset Email</button>
                                    <p className="mt-3">
                                        <a className="psa-inline-button" href="mailto:help@stmartinpsa.org">
                                            Need help logging in? Email us.
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PasswordResetPage);
