import React from "react";
import moment from "moment";

import Event from "../../core/interfaces/Event";

interface EventListItemProps {
    event: Event;
}

const EventListItem: React.FC<EventListItemProps> = ({event}: EventListItemProps) => {
    return (
        <a href={"/events/" + event.slug} className="list-group-item list-group-item-action flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1 font-weight-light text-dark">{event.name}</h5>
            </div>
            { event.isAllDay ? (
                <p className="font-weight-medium text-muted">
                    Starts {moment(new Date(event.dateStart * 1000)).format("dddd MMMM Do YYYY")}
                </p>
            ) : (
                <p className="font-weight-medium text-muted">{moment(new Date(event.dateStart * 1000)).format("dddd MMMM Do YYYY")}</p>
            )}
            <div className="d-flex w-100 justify-content-between">
                <small className="text-muted">School Year: {event.schoolYear.label}</small>
                { event.hasPendingSubmissions && <span className="badge badge-success badge-height text-uppercase">New Submissions</span> }
            </div>
        </a>
    );
}

export default EventListItem;
