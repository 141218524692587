import Event from "./interfaces/Event";
import Authenticator from "./Authenticator";
import Parent from "./interfaces/Parent";
import PIPSubmission from "./interfaces/PIPSubmission";
import { PIPReportItem } from "./interfaces/PIPSubmission";

const axios = require("axios").default;

export default class EventService {
    async getEvents(): Promise<Event[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/years/3/events`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });
            
            const events: Event[] = response.data;
            return events;
        } catch (error) {
            return [];
        }
    }

    async getEvent(slug: string): Promise<Event | null> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/events/${slug}`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const event: Event = response.data;
                return event;
            }
        } catch (error) {
            return null;
        }
        
        return null;
    }

    async getEventsForChair(): Promise<Event[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/events/chair`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            const events: Event[] = response.data;
            return events;
        } catch (error) {
            return [];
        }
    }

    async submitPIPHours(eventId: number, requestType: string, submission: string, hoursValue: number, familyId?: number): Promise<boolean> {
        const authenticator = new Authenticator();
        const user = authenticator.getUser();

        if (user == null) {
            return false;
        }

        if (familyId == null) {
            familyId = user.familyId;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_PSA_API_HOST}/pip/new`, {
                "eventId": eventId,
                "familyId": familyId,
                "submissionType": requestType,
                "submissionValue": submission,
                "hoursValue": hoursValue
            }, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            return response.status === 201;
        } catch (error) {
            return false;
        }
    }

    async approvePIPHours(submissionId: number): Promise<PIPSubmission | null> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_PSA_API_HOST}/pip/${submissionId}/approve`, {}, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const submission: PIPSubmission = response.data;
                return submission;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    async denyPIPHours(submissionId: number): Promise<PIPSubmission | null> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.post(`${process.env.REACT_APP_PSA_API_HOST}/pip/${submissionId}/deny`, {}, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const submission: PIPSubmission = response.data;
                return submission;
            }

            return null;
        } catch (error) {
            return null;
        }
    }

    async getPIPSubmissions(): Promise<PIPSubmission[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/users/pip`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const submissions: PIPSubmission[] = response.data;
                return submissions;
            }

            return [];
        } catch (error) {
            return [];
        }
    }

    async getPIPSubmissionsForEvent(slug: string): Promise<PIPSubmission[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/events/${slug}/pip`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const submissions: PIPSubmission[] = response.data;
                return submissions;
            }

            return [];
        } catch (error) {
            return [];
        }
    }

    async getParents(): Promise<Parent[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/pip/parents`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const parents: Parent[] = response.data;
                return parents;
            }

            return [];
        } catch (error) {
            return [];
        }
    }

    async getPIPReport(showAll: boolean): Promise<PIPReportItem[]> {
        const authenticator = new Authenticator();

        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/pip/report?showAll=${showAll}`, {
                headers: {
                    "Authorization": authenticator.getToken()
                }
            });

            if (response.status === 200) {
                const items: PIPReportItem[] = response.data;
                return items;
            }

            return [];
        } catch (error) {
            return [];
        }
    }
}
