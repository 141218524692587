import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";

import Navbar from "../../components/navbar/Navbar";
import EventService from "../../core/EventService";
import PIPSubmission from "../../core/interfaces/PIPSubmission";
import PIPHourList from "../../components/pip-hour-list/PIPHourList";
import Authenticator from "../../core/Authenticator";
import EventList from "../../components/event-list/EventList";

import Event from "../../core/interfaces/Event";
import Footer from "../../components/footer/Footer";

interface DashboadPageProps {
    history: History;
}

function openPIPSubmit(event: React.MouseEvent, history: History) {
    event.preventDefault();
    history.push("/pip/submit");
}

async function fetchPIPHours(setSubmissions: React.Dispatch<PIPSubmission[]>, setTotalHours: React.Dispatch<number>) {
    const service = new EventService();

    const submissions = await service.getPIPSubmissions();
    setSubmissions(submissions);

    let totalHours = 0;
    submissions.map((submission) => {
        if (submission.event.schoolYear.isActive && (submission.isApproved || submission.isPending)) {
            totalHours += submission.hoursValue;
        }

        return;
    });

    setTotalHours(totalHours);
}

async function fetchEventsForChairperson(setEvents: React.Dispatch<Event[]>) {
    const authenticator = new Authenticator();
    let user = authenticator.getUser();

    if (user == null) {
        user = await authenticator.refreshUser();

        if (user == null) {
            $("#col-chairperson").addClass("collapse");
        }

        return;
    }

    const service = new EventService();
    const events = await service.getEventsForChair();

    setEvents(events);

    if (events.length > 0) {
        $("#col-chairperson").removeClass("collapse");
    } else {
        $("#col-chairperson").addClass("collapse");
    }
}

const DashboardPage: React.FC<DashboadPageProps> = ({history}: DashboadPageProps) => {
    const [totalHours, setTotalHours] = useState<number>();
    const [submissions, setSubmissions] = useState<PIPSubmission[]>([]);

    const [events, setEvents] = useState<Event[]>([]);

    useEffect(() => {
        fetchPIPHours(setSubmissions, setTotalHours);
        fetchEventsForChairperson(setEvents);
    }, []);

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mb-4">
                <div className="row justify-content-center">
                    <div id="col-chairperson" className="col-lg-6 mt-4">
                        <div className="card">
                            <div className="card-header d-flex w-100">
                                Events
                            </div>
                            <div className="card-body m-0 p-0">
                                <EventList events={events} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 mt-4">
                        <div className="alert alert-primary show" role="alert">
                            <span className="font-weight-bold">{totalHours} hours</span> completed out of the required <span className="font-italic">20 hours</span> for the current school year.
                        </div>
                        <div className="card">
                            <div className="card-header d-flex w-100">
                                <span className="mt-1">Your PIP Hours</span>
                                <button className="btn btn-sm btn-outline-primary ml-auto" onClick={e => openPIPSubmit(e, history)}>Submit Hours</button>
                            </div>
                            <div className="card-body m-0 p-0">
                                <PIPHourList submissions={submissions} />
                            </div>
                        </div>
                        <div className="alert alert-light show mt-4" role="alert">
                            <span>Need help using the website? Send us an email at <a href="mailto:help@stmartinpsa.org">help@stmartinpsa.org</a> with any questions, we'd be happy to help.</span>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(DashboardPage);
