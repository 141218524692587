import React from "react";

import AdminPIPHourListItem from "./AdminPIPHourListItem";
import PIPSubmission from "../../core/interfaces/PIPSubmission";

interface AdminPIPHourListProps {
    submissions: PIPSubmission[];
}

const AdminPIPHourList: React.FC<AdminPIPHourListProps> = ({submissions}: AdminPIPHourListProps) => {
    console.log("Submissions: " + JSON.stringify(submissions));
    return (
        <div className="container m-0 p-0">
            <ul className="list-group list-group-flush m-0 p-0">
                {submissions.map((submission) =>
                    <AdminPIPHourListItem key={submission.id} pipSubmission={submission} />
                )}
                {submissions.length == 0 &&
                    <li className="list-group-item flex-column mx-auto empty-list">
                        <p className="text-muted mt-3">No PIP Hours</p>
                    </li>
                }
            </ul>
        </div>
    );
}

export default AdminPIPHourList;
