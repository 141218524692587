import React from "react";

import PIPSubmission from "../../core/interfaces/PIPSubmission";
import PIPHourListItem from "./PIPHourListItem";

import "./pip-hour-list.scss";

interface PIPHourListProps {
    submissions: PIPSubmission[];
}

const PIPHourList: React.FC<PIPHourListProps> = ({submissions}: PIPHourListProps) => {
    return (
        <div className="container m-0 p-0">
            <ul className="list-group list-group-flush m-0 p-0">
                {submissions.map((submission) =>
                    <PIPHourListItem key={submission.id} submission={submission} />
                )}
                {submissions.length == 0 &&
                    <li className="list-group-item flex-column mx-auto empty-list">
                        <p className="text-muted mt-3">No PIP Hours</p>
                    </li>
                }
            </ul>
        </div>
    );
}

export default PIPHourList;
