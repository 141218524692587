import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import queryString from "query-string";
import $ from "jquery";

import schoolLogo from "../../images/school-logo.svg";
import Authenticator from "../../core/Authenticator";

interface NewPasswordPageProps {
    history: History;
}

async function executePasswordUpate(password: string, confirmPassword: string, token: string, history: History) {
    if (password !== confirmPassword) {
        $("#password-error-alert").text("Passwords entered do not match.");
        $("#password-error-alert").show();
        return;
    }

    if (password.length < 8) {
        $("#password-error-alert").text("Password is too short.");
        $("#password-error-alert").show();
        return;
    }

    const authenticator = new Authenticator();
    const success = await authenticator.updatePassword(password, token);

    if (success) {
        history.replace("/login");
    } else {
        $("#password-error-alert").text("Password reset expired. Please request a new one.");
        $("#password-error-alert").show();
        return;
    }
}

const NewPasswordPage: React.FC<NewPasswordPageProps> = ({history}: NewPasswordPageProps) => {
    const params = queryString.parse(history.location.search);

    const [password, setPassword] = useState();
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const [confirmPassword, setConfirmPassword] = useState();
    const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(event.target.value);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        const token = params["token"] as string;
        executePasswordUpate(password, confirmPassword, token, history);
    };

    return (
        <div className="container">
            <div className="row mt-4 justify-content-center align-items-center">
                <div className="col-lg-4">
                    <img src={schoolLogo} alt="St. Martin de Porres Logo" className="col-12 logo" />
                    <div className="card mt-4">
                        <div className="card-header">
                            Create a new password
                        </div>
                        <div className="card-body">
                            <div id="password-error-alert" className="alert alert-danger collapse"></div>
                            <form onSubmit={formSubmit}>
                                <input type="password" className="form-control mt-2" placeholder="Password" onChange={handlePasswordChange} />
                                <input type="password" className="form-control mt-2" placeholder="Confirm Password" onChange={handleConfirmPasswordChange} />
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn-primary col-12 psa-button">Update Password</button>
                                    <p className="mt-3">
                                        <a className="psa-inline-button" href="mailto:help@stmartinpsa.org">
                                            Need help? Email us.
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewPasswordPage);
