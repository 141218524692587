import React from "react";

const Footer: React.FC = () => {
    return (
        <footer className="flex-shrink-0 mb-auto">
            <small className="text-muted">&copy; 2023 St. Martin de Porres School PSA</small>
        </footer>
    );
}

export default Footer;
