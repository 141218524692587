import User from "./interfaces/User";
const axios = require("axios").default;

export default class Authenticator {
    isAuthenticated(): boolean {
        const token = localStorage.getItem("api_token");
        return token != null && token.length > 0;
    }

    logout() {
        localStorage.removeItem("api_token");
    }

    getToken(): string | null {
        return localStorage.getItem("api_token");
    }

    getUser(): User | null {
        const json = localStorage.getItem("user");
        if (json == null) {
            return null;
        }

        const user: User = JSON.parse(json);
        return user;
    }

    async refreshUser(): Promise<User | null> {
        try {
            const response = await axios.get(`${process.env.REACT_APP_PSA_API_HOST}/users/me`, {
                headers: {
                    "Authorization": this.getToken()
                }
            });
            const user: User = response.data;
            localStorage.setItem("user", JSON.stringify(user));

            return user;
        } catch (error) {
            return null;
        }
    }

    async authenticate(email: string, password: string): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_PSA_API_HOST}/users/authenticate`, {
                "email": email,
                "password": password
            });
            const user: User = response.data;
            localStorage.setItem("api_token", response.headers.authorization);
            localStorage.setItem("user", JSON.stringify(user));

            return true;
        } catch (error) {
            return false;
        }
    }

    async initiatePasswordReset(email: string): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_PSA_API_HOST}/users/password/reset`, {
                "email": email,
                "domain": "pip.stmartinpsa.org"
            });

            if (response.status === 202) {
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    async updatePassword(password: string, token: string): Promise<boolean> {
        try {
            const response = await axios.post(`${process.env.REACT_APP_PSA_API_HOST}/users/password`, {
                "password": password,
                "tokenDigest": token
            });

            if (response.status !== 200) {
                return false;
            }

            return true;
        } catch (error) {
            return false;
        }
    }
}
