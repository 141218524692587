import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";

import Event from "../../core/interfaces/Event";
import EventService from "../../core/EventService";
import Navbar from "../../components/navbar/Navbar";

interface NewPIPHoursPageProps {
    history: History;
}

async function fetchEvents(setEvents: React.Dispatch<Event[]>, setEventId: React.Dispatch<number>) {
    const service = new EventService();

    const events = await service.getEvents();
    setEvents(events);

    if (events.length > 0) {
        setEventId(events[0].id);
    }
}

async function submitHours(eventId: number, requestType: string, submission: string, hoursValue: number) {
    $("#pip-alert").hide();

    if (Number.isNaN(eventId) || eventId === 0) {
        $("#pip-alert").text("Event not selected.");
        $("#pip-alert").show();
        
        return;
    }

    if (requestType === undefined || requestType.length <= 0) {
        $("#pip-alert").text("Please select either Donation or Volunteer.");
        $("#pip-alert").show();
        return;
    }

    if (submission === undefined || submission.length <= 0) {
        if (requestType === "donation") {
            $("#pip-alert").text("Please enter the item you donated.");
        } else {
            $("#pip-alert").text("Please enter the number of hours you volunteered.");
        }

        $("#pip-alert").show();
        return;
    }

    if (hoursValue <= 0) {
        $("#pip-alert").text("Please enter the number of hours.");
        return;
    }

    const service = new EventService();

    const success = await service.submitPIPHours(eventId, requestType, submission, hoursValue);
    if (success) {
        $("#pip-success-modal").modal("show");
    } else {
        $("#pip-alert").text("There was an error processing your submission. Please try again.");
        $("#pip-alert").show();
    }
}

function cancel(event: React.MouseEvent, history: History) {
    event.preventDefault();
    history.goBack();
}

const NewPIPHoursPage: React.FC<NewPIPHoursPageProps> = ({history}: NewPIPHoursPageProps) => {
    const [eventId, setEventId] = useState();
    const handleEventId = (event: React.ChangeEvent<HTMLSelectElement>) => setEventId(event.target.value);

    const [events, setEvents] = useState<Event[]>([]);
    useEffect(() => {
        fetchEvents(setEvents, setEventId);
    }, []);

    const [requestType, setRequestType] = useState();
    const handleRequestType = (event: React.ChangeEvent<HTMLInputElement>) => setRequestType(event.target.value);

    const [volunteerSubmission, setVolunteerSubmission] = useState();
    const handleVolunteerSubmission = (event: React.ChangeEvent<HTMLInputElement>) => setVolunteerSubmission(event.target.value);

    const [donationSubmission, setDonationSubmission] = useState();
    const handleDonationSubmission = (event: React.ChangeEvent<HTMLInputElement>) => setDonationSubmission(event.target.value);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        
        let submission = volunteerSubmission;
        if (requestType === "donation") {
            submission = donationSubmission;
        }

        if (Number.isNaN(volunteerSubmission)) {
            $("#pip-alert").text("Please enter a number for hours");
            $("#pip-alert").show();
            return;
        }

        submitHours(Number(eventId), requestType, submission, Number(volunteerSubmission));
    };

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container mt-3">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div id="pip-success-modal" className="modal" tabIndex={-1} role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Submit PIP Hours</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>Your PIP hours have been submitted. You'll receive an email confirmation shortly.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                            onClick={(e) => { cancel(e, history); }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                Submit PIP Hours
                            </div>
                            <div className="card-body">
                                <div id="pip-alert" className="alert alert-danger collapse"></div>
                                <form onSubmit={formSubmit}>
                                    <label htmlFor="formEvent" className="col-form-label">Event Name</label>
                                    <select id="formEvent" className="form-control mt-2" onChange={handleEventId} value={eventId}>
                                        {events.map((event) =>
                                            <option key={event.id} value={event.id}>{event.name}</option>
                                        )}
                                    </select>
                                    <div className="form-check mt-3">
                                        <input type="radio" className="form-check-input" name="formHoursType" id="formHoursDonation" value="donation" onChange={(e) => {
                                            handleRequestType(e);
                                            $("#donationItemGroup").show();
                                        }} />
                                        <label htmlFor="formHoursDonation" className="form-check-label">Donation</label>
                                    </div>
                                    <div className="form-check mt-3">
                                        <input type="radio" className="form-check-input" name="formHoursType" id="formHoursVolunteer" value="volunteer" onChange={(e) => {
                                            handleRequestType(e);
                                            $("#donationItemGroup").hide();
                                            $("#volunteerHoursGroup").show();
                                        }} />
                                        <label htmlFor="formHoursVolunteer" className="form-check-label">Volunteer</label>
                                    </div>
                                    <div id="volunteerHoursGroup" className="form-group mt-3">
                                        <label htmlFor="formNumberOfHours" className="col-form-label-sm">Number of hours</label>
                                        <input type="text" className="form-control" id="formNumberOfHours" name="formNumberOfHours"
                                            placeholder="1" inputMode="decimal" onChange={(e) => { handleVolunteerSubmission(e); }} />
                                    </div>
                                    <div id="donationItemGroup" className="form-group mt-3 collapse">
                                        <label htmlFor="formDonationItem" className="col-form-label-sm">Item donated</label>
                                        <input type="number" className="form-control" id="formDonationItem" name="formDonationItem"
                                            placeholder="Case of water" inputMode="text" onChange={(e) => { handleDonationSubmission(e); }} />
                                    </div>
                                    <div className="mt-4 text-center">
                                        <button type="submit" className="btn btn-primary col-12 psa-button">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(NewPIPHoursPage);
