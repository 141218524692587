import React from "react";

import { PIPReportItem } from "../../core/interfaces/PIPSubmission";

interface ReportListItemProps {
    reportItem: PIPReportItem;
}

const ReportListItem: React.FC<ReportListItemProps> = ({reportItem}: ReportListItemProps) => {
    return (
        <div className="list-group-item flex-column align-items-start">
          <div className="w-100">
              <p className="mb-1 font-weight-light text-dark">{reportItem.LastName}</p>
              <p style={{ margin: 0, padding: 0 }} className='text-dark'>{reportItem.TotalHours} hours ({reportItem.ItemHours} hours from donated items)</p>
          </div>
        </div>
    );
}

export default ReportListItem;
