import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";
import Authenticator from "../../core/Authenticator";

import AdminPIPHourList from "../../components/pip-hour-list/AdminPIPHourList";
import Event from "../../core/interfaces/Event";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import EventService from "../../core/EventService";
import EventDetail from "../../components/event-detail/EventDetail";
import PIPSubmission from "../../core/interfaces/PIPSubmission";

interface EventAdminPageProps {
    history: History;
}

async function fetchEvent(slug: string, setEvent: React.Dispatch<Event>, setSubmissions: React.Dispatch<PIPSubmission[]>) {
    const service = new EventService();
    const event = await service.getEvent(slug);

    if (event != null) {
        setEvent(event);
    }

    const submissions = await service.getPIPSubmissionsForEvent(slug);
    setSubmissions(submissions);
}

function openNewPIPHoursPage(history: History, event: Event) {
    history.push("/events/pip", {
        event: event
    });
}

const EventAdminPage: React.FC<EventAdminPageProps> = ({history}: EventAdminPageProps) => {
    const slug = history.location.pathname.split("/")[2];

    const [event, setEvent] = useState<Event | null>(null);
    const [submissions, setSubmissions] = useState<PIPSubmission[] | null>(null);
    useEffect(() => {
        fetchEvent(slug, setEvent, setSubmissions);
    }, []);

    useEffect(() => {
        const authenticator = new Authenticator();
        let user = authenticator.getUser();

        if (user?.isAdmin === false) {
            history.replace("/");
        }
    }, []);

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div id="test-div" className="container flex-grow-1 mb-4">
                <div className="row">
                    <div className="col-lg-4 mt-4">
                        <div className="card">
                            <div className="card-header p-lg-3">
                                Details
                            </div>
                            <div className="card-body">
                                { event != null &&
                                    <EventDetail event={event} />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 mt-4">
                        <div className="card">
                            <div className="card-header d-flex w-100">
                                <span className="mt-1">PIP Hours</span>
                                { event != null && 
                                <button className="btn btn-sm btn-outline-primary ml-auto" onClick={(e) => openNewPIPHoursPage(history, event)}>New PIP Hours</button>
                                }
                            </div>
                            <div className="card-body m-0 p-0">
                                { submissions != null &&
                                    <AdminPIPHourList submissions={submissions} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(EventAdminPage);
