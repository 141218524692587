import React, { useState } from "react";
import $ from "jquery";

import PIPSubmission from "../../core/interfaces/PIPSubmission";
import EventService from "../../core/EventService";

interface AdminPIPHourListItemProps {
    pipSubmission: PIPSubmission;
}

async function approveHours(submission: PIPSubmission, event: React.MouseEvent, setSubmission: React.Dispatch<PIPSubmission>) {
    event.preventDefault();

    const service = new EventService();

    const updatedSubmission = await service.approvePIPHours(submission.id);
    if (updatedSubmission != null) {
        setSubmission(updatedSubmission);
    }
}

async function denyHours(submission: PIPSubmission, event: React.MouseEvent, setSubmission: React.Dispatch<PIPSubmission>) {
    event.preventDefault();

    const service = new EventService();

    const updatedSubmission = await service.denyPIPHours(submission.id);
    if (updatedSubmission != null) {
        setSubmission(updatedSubmission);
    }
}

const AdminPIPHourListItem: React.FC<AdminPIPHourListItemProps> = ({pipSubmission}: AdminPIPHourListItemProps) => {
    const [submission, setSubmission] = useState<PIPSubmission>(pipSubmission);

    return (
        <li className="list-group-item flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between mt-n2 mb-n2">
                <div>
                    <div>
                        <small className="text-muted text-uppercase">Family</small>
                        <br />
                        <p className="mt-n1">
                            {submission.family.lastName}
                        </p>
                    </div>
                    <div className="mt-n2">
                        <small className="text-muted text-uppercase mt-n2">{submission.submissionType}</small>
                        { submission.submissionType === "volunteer" ? (
                            <p className="mt-n1">{submission.hoursValue} hours</p>
                        ) : (
                            <p className="mt-n1">{submission.submissionValue}</p>
                        )}
                    </div>
                    <small className="text-muted font-italic">Submitted by: {submission.submittedByUser.firstName} {submission.submittedByUser.lastName}</small>
                </div>
                { submission.isPending &&
                    <div className="my-auto"> 
                        <div>
                            <button className="btn btn-sm btn-outline-success w-100" onClick={e => approveHours(submission, e, setSubmission)}>Approve</button>
                        </div>
                        <div className="mt-3">
                            <button className="btn btn-sm btn-outline-danger w-100" onClick={e => denyHours(submission, e, setSubmission)}>Deny</button>
                        </div>
                    </div>
                }
                { submission.isApproved &&
                    <div className="my-auto">
                        <span className="badge badge-success text-uppercase p-2">Approved</span>
                    </div>
                }
                { (!submission.isApproved && !submission.isPending) && 
                    <div className="my-auto">
                        <span className="badge badge-danger text-uppercase p-2">Denied</span>
                    </div>
                }
            </div>
        </li>
    );
}

export default AdminPIPHourListItem;
