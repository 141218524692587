import React from "react";
import moment from "moment";

import PIPSubmission from "../../core/interfaces/PIPSubmission";

interface PIPHourListItemProps {
    submission: PIPSubmission;
}

const PIPHourListItem: React.FC<PIPHourListItemProps> = ({submission}: PIPHourListItemProps) => {
    return (
        <li className="list-group-item flex-column align-items-start">
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1 font-weight-light">{submission.event.name}</h5>
                <small className="font-weight-medium">{moment(new Date(submission.createdAt * 1000)).fromNow()}</small>
            </div>
            <div className="d-flex w-100 justify-content-between">
                <p>
                    { submission.submissionType === "volunteer" ? (
                        <span>{submission.hoursValue} hours volunteered</span>
                    ) : (
                        <span>{submission.submissionValue} donated</span>
                    )}
                </p>
                { submission.isPending && <span className="badge badge-primary badge-height text-uppercase">Pending</span> }
                { submission.isApproved && <span className="badge badge-success badge-height text-uppercase">Approved</span> }
                { (!submission.isApproved && !submission.isPending) && <span className="badge badge-danger badge-height text-uppercase">Denied</span> }
            </div>
            <small className="text-muted">School Year: {submission.event.schoolYear.label}</small>
        </li>
    );
}

export default PIPHourListItem;
