import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch
} from "react-router-dom";

import AdminPIPHoursPage from "./pages/new-pip-hours/AdminPIPHoursPage";
import Authenticator from "./core/Authenticator";
import DashboardPage from "./pages/dashboard/DashboardPage";
import EventAdminPage from "./pages/events/EventAdminPage";
import LoginPage from "./pages/login/LoginPage";
import NewPasswordPage from "./pages/password-reset/NewPasswordPage";
import PasswordResetPage from "./pages/password-reset/PasswordResetPage";
import NewPIPHoursPage from "./pages/new-pip-hours/NewPIPHoursPage";
import ReportPage from "./pages/reports/ReportPage";

interface RouteProps {
  path: string;
  component: typeof Component;
}

const ProtectedRoute = ({component: Component, ...rest}: RouteProps) => {
  const authenticator = new Authenticator();

  return (
      <Route {...rest}
          render={props => !authenticator.isAuthenticated() ? 
              ( <Redirect to={{ pathname: "/login" }} /> ) : ( <Component {...props} /> )} />
  );
}

const App: React.FC = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/login">
                    <LoginPage />
                </Route>
                <Route exact path="/password/reset">
                    <PasswordResetPage />
                </Route>
                <Route path="/password/new">
                    <NewPasswordPage />
                </Route>
                <ProtectedRoute path="/pip/submit" component={NewPIPHoursPage} />
                <ProtectedRoute path="/events/pip" component={AdminPIPHoursPage} />
                <ProtectedRoute path="/events/:slug" component={EventAdminPage} />
                <ProtectedRoute path="/reports" component={ReportPage} />
                <ProtectedRoute path="/" component={DashboardPage} />
            </Switch>
        </Router>
    );
}

export default App;
