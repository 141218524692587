import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import Navbar from "../../components/navbar/Navbar";
import EventService from "../../core/EventService";
import { PIPReportItem } from "../../core/interfaces/PIPSubmission";
import ReportList from "../../components/report-list/report-list";

interface ReportPageProps {
  history: History;
}

async function fetchReport(showAll: boolean, setReport: React.Dispatch<PIPReportItem[]>) {
  const service = new EventService();

  const report = await service.getPIPReport(showAll);
  setReport(report);
}

const ReportPage: React.FC<ReportPageProps> = ({ history }) => {
  const [report, setReport] = useState<PIPReportItem[]>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const onShowToggled = () => {
    const show = !showAll;

    setShowAll(show);
    fetchReport(show, setReport);
  };

  useEffect(() => {
    fetchReport(showAll, setReport);
  }, []);

  return (
    <div>
      <Navbar history={history} />
      <div className="container">
      <div className="row justify-content-center">
          <div className="col-lg-12 mt-4">
              <div className="card">
                  <div className="card-header d-flex w-100">
                      <span className="mt-1">PIP Report</span>
                      <span className="my-2 my-lg-0 ml-auto"><input type="checkbox" name="show-all" value='true' onClick={(e) => { onShowToggled(); }} /> Show All</span>
                  </div>
                  <div className="card-body">
                    <ReportList reportItems={report} />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReportPage);
