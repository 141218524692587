import React from "react";
import moment from "moment";

import Event from "../../core/interfaces/Event";

interface EventDetailProps {
    event: Event;
}

const EventDetail: React.FC<EventDetailProps> = ({event}: EventDetailProps) => {    
    const start = new Date(event.dateStart * 1000);
    const end = new Date(event.dateEnd * 1000);

    let formattedDay = moment(start).format("dddd MMMM Do YYYY");
    let formattedTime = moment(start).format("h:mm a");
    let combiner = "at";

    if (start.getDay() === end.getDay()) {
        formattedTime += " to " + moment(end).format("h:mm a");
    } else {
        formattedTime = moment(end).format("dddd MMMM Do YYYY");
        combiner = "through";
    }

    return (
        <div>
            <h4>{event.name}</h4>
            <div className="mt-n2">
                <small className="text-muted">School Year: {event.schoolYear.label}</small>
            </div>
            <div className="mt-2">
                <span>{formattedDay}</span>
                <br/>
                <span>{combiner}</span>
                <br/>
                <span>{formattedTime}</span>
            </div>
            <div className="mt-4">
                <h5 className="font-weight-medium">Chairperson(s)</h5>
                {event.chairs.map((chair) =>
                    <p>{chair.firstName} {chair.lastName}</p>
                )}
                { event.chairs.length === 0 && <p className="font-italic">No chairs for event</p> }
            </div>
        </div>
    );
}

export default EventDetail;
