import React from "react";

import ReportListItem from "./report-list-item";
import { PIPReportItem } from "../../core/interfaces/PIPSubmission";

interface ReportListProps {
    reportItems: PIPReportItem[];
}

const ReportList: React.FC<ReportListProps> = ({reportItems}: ReportListProps) => {
    return (
        <div className="container m-0 p-0">
            <div className="list-group list-group-flush m-0 p-0">
                {reportItems.map((reportItem) =>
                    <ReportListItem key={reportItem.FamilyID} reportItem={reportItem} />
                )}
            </div>
        </div>
    );
}

export default ReportList;
