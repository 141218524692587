import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";

import Authenticator from "../../core/Authenticator";

import "./login-page.scss";
import schoolLogo from "../../images/school-logo.svg";

interface LoginPageProps {
    history: History;
}

async function executeLogin(email: string, password: string, history: History) {
    $("#login-alert").hide();

    const authenticator = new Authenticator();
    const success = await authenticator.authenticate(email, password);

    if (success) {
        history.replace("/");
    } else {
        $("#login-alert").show();
    }
}

const LoginPage: React.FC<LoginPageProps> = ({history}: LoginPageProps) => {
    const [email, setEmail] = useState();
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value);

    const [password, setPassword] = useState();
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => setPassword(event.target.value);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        executeLogin(email, password, history);
    };

    return (
        <div className="container">
            <div className="row mt-4 justify-content-center align-items-center">
                <div className="col-lg-4">
                    <img src={schoolLogo} alt="St. Martin de Porres Logo" className="col-12 logo" />
                    <div className="card mt-4">
                        <div className="card-body">
                            <div id="login-alert" className="alert alert-danger collapse">
                                Email or password is incorrect.
                            </div>
                            <form onSubmit={formSubmit}>
                                <input type="email" className="form-control mt-2" placeholder="Email" onChange={handleEmailChange} />
                                <input type="password" className="form-control mt-2" placeholder="Password" onChange={handlePasswordChange} />
                                <div className="mt-4 text-center">
                                    <button type="submit" className="btn btn-primary col-12 psa-button">Login</button>
                                    <p className="mt-3">
                                        <a className="psa-inline-button" href="/password/reset">
                                            Forgot password? Click here to reset.
                                        </a>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(LoginPage);
