import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";
import $ from "jquery";

import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import Parent from "../../core/interfaces/Parent";
import EventService from "../../core/EventService";

interface AdminPIPHoursPageProps {
    history: History;
}

async function fetchParents(setParents: React.Dispatch<Parent[]>, setFamilyId: React.Dispatch<number>) {
    const service = new EventService();
    
    const parents = await service.getParents();
    setParents(parents);

    if (parents.length > 0) {
        setFamilyId(parents[0].familyId);
    }
}

async function submitHours(eventId: number, requestType: string, submission: string, hoursValue: number, familyId: number) {
    $("#pip-alert").hide();

    if (Number.isNaN(eventId) || eventId === 0) {
        $("#pip-alert").text("Event not selected.");
        $("#pip-alert").show();
        
        return;
    }

    if (requestType === undefined || requestType.length <= 0) {
        $("#pip-alert").text("Please select either Donation or Volunteer.");
        $("#pip-alert").show();
        return;
    }

    if (submission === undefined || submission.length <= 0) {
        if (requestType === "donation") {
            $("#pip-alert").text("Please enter the item you donated.");
        } else {
            $("#pip-alert").text("Please enter the number of hours you volunteered.");
        }

        $("#pip-alert").show();
        return;
    }

    if (hoursValue <= 0) {
        $("#pip-alert").text("Please enter the number of hours.");
        return;
    }

    const service = new EventService();

    const success = await service.submitPIPHours(eventId, requestType, submission, hoursValue, familyId);
    if (success) {
        $("#pip-success-modal").modal("show");
    } else {
        $("#pip-alert").text("There was an error processing your submission. Please try again.");
        $("#pip-alert").show();
    }
}

function cancel(event: React.MouseEvent, history: History) {
    event.preventDefault();
    history.goBack();
}

const AdminPIPHoursPage: React.FC<AdminPIPHoursPageProps> = ({history}: AdminPIPHoursPageProps) => {
    const [familyId, setFamilyId] = useState();
    const handleFamilyId = (event: React.ChangeEvent<HTMLSelectElement>) => setFamilyId(event.target.value);

    const [requestType, setRequestType] = useState();
    const handleRequestType = (event: React.ChangeEvent<HTMLInputElement>) => setRequestType(event.target.value);

    const [volunteerSubmission, setVolunteerSubmission] = useState();
    const handleVolunteerSubmission = (event: React.ChangeEvent<HTMLInputElement>) => setVolunteerSubmission(event.target.value);

    const [donationSubmission, setDonationSubmission] = useState();
    const handleDonationSubmission = (event: React.ChangeEvent<HTMLInputElement>) => setDonationSubmission(event.target.value);

    const [parents, setParents] = useState<Parent[]>([]);
    useEffect(() => {
        fetchParents(setParents, setFamilyId);
    }, []);

    const formSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        let submission = volunteerSubmission;
        if (requestType === "donation") {
            submission = donationSubmission;
        }

        submitHours(Number(history.location.state.event.id), requestType, submission, Number(volunteerSubmission), Number(familyId));
    };

    return (
        <div className="d-flex flex-column full-height">
            <Navbar history={history} />
            <div className="container flex-grow-1 mt-3 mb-4">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div id="pip-success-modal" className="modal" tabIndex={-1} role="dialog">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">PIP Hours Submitted</h5>
                                    </div>
                                    <div className="modal-body">
                                        <p>PIP hours have been submitted and approved. The family will receive an email notification shortly.</p>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                            onClick={(e) => { cancel(e, history); }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                New PIP Hours for {history.location.state.event.name}
                            </div>
                            <div className="card-body">
                                <div id="pip-alert" className="alert alert-danger collapse"></div>
                                <form onSubmit={formSubmit}>
                                    <label htmlFor="formFamily" className="col-form-label">Parent</label>
                                    <select id="formFamily" className="form-control mt-2" onChange={handleFamilyId} value={familyId}>
                                        {parents.map((parent) =>
                                            <option key={parent.id} value={parent.familyId}>{parent.lastName}, {parent.firstName} ({parent.familyName} Family)</option>
                                        )}
                                    </select>
                                    <div className="form-check mt-3">
                                        <input type="radio" className="form-check-input" name="formHoursType" id="formHoursDonation" value="donation" onChange={(e) => {
                                            handleRequestType(e);
                                            $("#donationItemGroup").show();
                                        }} />
                                        <label htmlFor="formHoursDonation" className="form-check-label">Donation</label>
                                    </div>
                                    <div className="form-check mt-3">
                                        <input type="radio" className="form-check-input" name="formHoursType" id="formHoursVolunteer" value="volunteer" onChange={(e) => {
                                            handleRequestType(e);
                                            $("#donationItemGroup").hide();
                                            $("#volunteerHoursGroup").show();
                                        }} />
                                        <label htmlFor="formHoursVolunteer" className="form-check-label">Volunteer</label>
                                    </div>
                                    <div id="volunteerHoursGroup" className="form-group mt-3">
                                        <label htmlFor="formNumberOfHours" className="col-form-label-sm">Number of hours</label>
                                        <input type="number" className="form-control" id="formNumberOfHours" name="formNumberOfHours"
                                            placeholder="1" inputMode="decimal" onChange={(e) => { handleVolunteerSubmission(e); }} />
                                    </div>
                                    <div id="donationItemGroup" className="form-group mt-3 collapse">
                                        <label htmlFor="formDonationItem" className="col-form-label-sm">Item donated</label>
                                        <input type="text" className="form-control" id="formDonationItem" name="formDonationItem"
                                            placeholder="Case of water" inputMode="text" onChange={(e) => { handleDonationSubmission(e); }} />
                                    </div>
                                    <div className="mt-4 text-center">
                                        <button type="submit" className="btn btn-primary col-12 psa-button">Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default withRouter(AdminPIPHoursPage);
