import React from "react";

import Event from "../../core/interfaces/Event";
import EventListItem from "./EventListItem";

import "./event-list.scss";

interface EventListProps {
    events: Event[];
}

const EventList: React.FC<EventListProps> = ({events}: EventListProps) => {
    return (
        <div className="container m-0 p-0">
            <div className="list-group list-group-flush m-0 p-0">
                {events.map((event) =>
                    <EventListItem key={event.id} event={event} />
                )}
            </div>
        </div>
    );
}

export default EventList;
